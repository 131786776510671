/* CURIOUS DATA: ALL "makeStyle" prefixes will TRANSFORM in production. Ex: makeStyle-Header-28 = jss28; */
/* ======================================== */
/* START General Template Modifications */
/* This style was used for showing FULL SCREEN map, now used for FULL SCREEN views */
body{
	background-color: -var(--discreet-white);
  /* Este ultimo overwlof: auto no lo tenia; */
  /* overflow: auto !important; */
  font-size: 16px;
}

/* .testMakeStyles-wrapper-1{
  background-color: var(--bluish-gray);
  top: 0;
  height: 100%;
  height: 100vh; Take out for MODAL ACTIVE
  overflow: hidden !important;
  position: relative;
}  */
.ps{
	overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
} 
.testMakeStyles-map-5 {
  margin-top: 0px !important;
  /* Testing transitions 
  overflow: hidden;
  height: 100%;
  /* Testing transitions */
}

.makeStyles-content-3{
	margin-top: 0px !important;
	padding: 0px !important;
  /* Testing transitions */
  overflow: hidden;
  height: 94.3%;
  /* Testing transitions */
}

.makeStyles-container-4 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

div#navbar>header{
	background-color: var(--bluish-black);
	-webkit-box-shadow: none !important;
	        box-shadow: none !important;
  height: 60px;
}

li.MuiListItem-root.makeStyles-listItem-15.MuiListItem-gutters, li.MuiListItem-root.jss15.MuiListItem-gutters{
	margin-right: 25px;
	margin-left: 25px;
}

div.MuiToolbar-root.MuiToolbar-regular.makeStyles-container-31.MuiToolbar-gutters, div.MuiToolbar-root.MuiToolbar-regular.jss31.MuiToolbar-gutters{
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.makeStyles-navLink-17, .jss17 {
	text-transform: none !important;
}

/* Underline bar for main navbar buttons */
/* a.MuiButtonBase-root.MuiButton-root.makeStyles-button-115.makeStyles-transparent-124.makeStyles-navLink-17.MuiButton-text:hover::after, a.MuiButtonBase-root.MuiButton-root.jss115.jss124.jss17.MuiButton-text:hover::after */
a.MuiButtonBase-root.MuiButton-root.makeStyles-button-153.makeStyles-transparent-162.makeStyles-navLink-17.MuiButton-text:hover::after{
  -webkit-animation: underline_animation 0.3s;
          animation: underline_animation 0.3s;
  background: rgb(2,251,253);
  background: -webkit-gradient(linear, left top, right top, from(rgba(2,251,253,1)), color-stop(50%, rgba(59,134,192,1)), to(rgba(102,46,145,1)));
  background: linear-gradient(to right, rgba(2,251,253,1) 0%, rgba(59,134,192,1) 50%, rgba(102,46,145,1) 100%);
  content:"";
  display: block;
  height: 2px;
  position: absolute;
  top: 34px;
  left: 15px;
  width: 40%;
}

/* Taking away previous "ripples" animations for main navbar buttons */
a.makeStyles-navLink-17:hover, a.makeStyles-navLink-17:focus, a.jss17:hover, a.jss17:focus{
	background: transparent;
}

/* Taking away previous "ripples" animations for main navbar buttons */
span.MuiTouchRipple-root{
	color: transparent;
}

div.makeStyles-flex-32 {
  text-align: right;
}
/* END General Template Modifications */
/* ======================================== */

/* ======================================== */
/* START Typography */
@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(../fonts/FontsFree-Net-Proxima-Nova-Reg.otf);
}

div,button{
  font-family: 'IBM Plex Serif', serif !important;
  font-weight: 400 !important;
}

h4{
  font-family: 'IBM Plex Serif', serif !important;
  font-weight: 600 !important;
}
/* END Typography */
/* ======================================== */

/* ============================= */
:root {
  --wine: #8C0034;
  --light-purple: #623268;
  --discreet-white: 255, 245, 247;
  --dark-purple: #443F6D;
  --dark-blue: #2F4666;
  --indigo-blue: 18, 166, 154;
}
/* ============================= */

/* ============================= */
/* GENERAL MOBILE STYLES */
@media screen and (min-width: 320px) and (max-width: 1279px){
  .testMakeStyles-wrapper-1{
    background-color: var(--bluish-gray);
    top: 0;
    overflow: hidden !important;
    position: relative;
  }

  .scroll-container{
    background-color: rgba(255,245,247, 0.2);
    height: 100vh;
    width: 100%;
    border: 2px solid var(--wine);
  }

  .scroll-container::after {
    background-image: url(https://i1.wp.com/machinesdontcare.files.wordpress.com/2009/06/permtexture.png);
    content: "";
    height: 300%;
    left: -50%;
    opacity: 0.2;
    position: fixed;
    top: -110%;
    width: 300%;
  }

  .scroll-container.grain::after {
    -webkit-animation:grain 0.5s steps(6) infinite;
            animation:grain 0.5s steps(6) infinite;
            -ms-animation:grain 0.5s steps(6) infinite;
  }

  /*START Coming Soon Component*/
  /*=============================*/
  div.row.coming_soon_text{
    color: #2F4666;
    padding: 56% 40px 10px;
    text-transform: uppercase;
    text-align: center;
  }

  div.row.coming_soon_text>div{
    color: #2F4666 !important;
  }

  div>div#center_text{
    box-shadow:0px 14px 17px -4px rgba(0, 0, 0, 0.25);
    height: calc(22vh);
    font-family: 'Proxima Nova' !important;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.5;
  }

  div.clocks_row{
    margin-right: 10px;
    margin-left: 10px;
  }

  .clock_col{
    margin-top: -25% !important;
  }

  .timer-container {
    align-items: center;
    justify-content: center;
  }

  .timer-container>div>div.clock_panel {
    background: transparent;
    /*border: solid 2px;*/
    padding: 1.5rem 0.5rem;
    color: var(--wine);
    text-align: center;
  }

  .timer-container>div>div.clock_panel:before {
    content: "";
    position: absolute;
    top: 6%;
    left: 6%;
    border: solid 2px;
    height: 90%;
    width: 90%;
  }
  /*=============================*/
  /*END Coming Soon Component*/
}
/* END GENERAL MOBILE STYLES */
/* ============================= */

/* ============================= */
/* START COMMON (360x640) Mobile  and (max-width: 374px)*/
@media screen and (min-width: 360px) and (max-width: 413px) and (max-height: 640px) {
  /*Main navbar width*/
  div.makeStyles-container-31, div.jss31 {
    max-width: 100% !important;
  }

  /*START Coming Soon Component*/
  /*=============================*/
  div.row.coming_soon_text{
    padding: 54% 40px 10px;
  }

  div>div#center_text{
    height: calc(22vh);
    font-size: 1.9em;
    font-weight: 400;
  }

  div.clocks_row{
    margin-right: 10px;
    margin-left: 10px;
  }

  .clock_col{
    margin-top: -25% !important;
  }

  .timer-container>div>div.clock_panel {
    padding: 1.4rem 0.5rem;
  }

  .timer-container>div>div.clock_panel:before {
    top: 6%;
    left: 6%;
    border: solid 2px;
    height: 90%;
    width: 90%;
  }

  .timer-container>div>div>span {
    font-size: 1.5rem;
  }
  /*=============================*/
  /*END Coming Soon Component*/

}
/* END COMMON (360x640) Mobile */
/* ============================= */

/* ============================= */
/* START (360x780) Mobile  and (max-width: 374px)*/
@media screen and (min-width: 360px) and (max-width: 413px) and (min-height: 641px) {
  /*Main navbar width*/
  div.makeStyles-container-31, div.jss31 {
    max-width: 100% !important;
  }

  /*START Coming Soon Component*/
  /*=============================*/
  div.row.coming_soon_text{
    padding: 70% 40px 10px;
  }

  div>div#center_text{
    height: calc(17vh);
    font-size: 1.9em;
    font-weight: 400;
  }

  div.clocks_row{
    margin-right: 10px;
    margin-left: 10px;
  }

  .clock_col{
    margin-top: -22.5% !important;
  }

  .timer-container>div>div.clock_panel {
    padding: 1.4rem 0.5rem;
  }

  .timer-container>div>div.clock_panel:before {
    top: 6%;
    left: 6%;
    border: solid 2px;
    height: 90%;
    width: 90%;
  }

  .timer-container>div>div>span {
    font-size: 1.5rem;
  }
  /*=============================*/
  /*END Coming Soon Component*/

}
/* END COMMON (360x780) Mobile */
/* ============================= */

/* ============================= */
/* START (414x896) Mobile */
@media screen and (min-width: 414px) {
  /*Main navbar width*/
  div.makeStyles-container-31, div.jss31 {
    max-width: 100% !important;
  }

  /*START Coming Soon Component*/
  /*=============================*/
  div.row.coming_soon_text{
    padding: 70% 40px 10px;
  }

  div>div#center_text{
    height: calc(16vh);
    font-size: 2.1em;
    font-weight: 400;
  }

  div.clocks_row{
    margin-right: 10px;
    margin-left: 10px;
  }

  .clock_col{
    margin-top: -17.5% !important;
  }

  .timer-container>div>div.clock_panel {
    padding: 1.4rem 0.5rem;
  }

  .timer-container>div>div.clock_panel:before {
    top: 6%;
    left: 6%;
    border: solid 2px;
    height: 90%;
    width: 90%;
  }

  .timer-container>div>div>span {
    font-size: 1.5rem;
  }
  /*=============================*/
  /*END Coming Soon Component*/
}
/* END (414x896) Mobile */
/* ============================= */

/* ============================= */
/* START (768x1024) Tablet */
@media screen and (min-width: 768px) and (min-height: 1024px) {
  /*Main navbar width*/
  div.makeStyles-container-31, div.jss31 {
    max-width: 100% !important;
  }

  /*START Coming Soon Component*/
  /*=============================*/
  div.row.coming_soon_text{
    padding: 45% 40px 10px;
  }

  div>div#center_text{
    height: calc(16vh);
    font-size: 2.1em;
    font-weight: 400;
  }

  div.clocks_row{
    margin-right: 10px;
    margin-left: 10px;
  }

  .clock_col{
    margin-top: -12.5% !important;
  }

  .timer-container>div>div.clock_panel {
    padding: 3em 0.2rem;
  }

  .timer-container>div>div.clock_panel:before {
    top: 6%;
    left: 11%;
    border: solid 2px;
    height: 90%;
    width: 80%;
  }

  .timer-container>div>div>span {
    font-size: 1.5rem;
  }
  /*=============================*/
  /*END Coming Soon Component*/
}
/* END (768x1024) Mobile */
/* ============================= */

/* ============================= */
/* START GENERAL PC/Tablet STYLES */
@media screen and (min-width: 1280px) {
  html{/*VARIABLES for this media query*/
    --cursorUpArrowTransform: translate(0px, 0px) rotate(180deg);
    --cursorDownArrowTransform: translate(0px, 0px);
  }

  .testMakeStyles-wrapper-1{
    background-color: rgba(var(--discreet-white), 0.5);
  }

  div{
    color: #8C0034;
    color: var(--wine) !important;
  }

  #down_arrow_tooltip{
    color: rgba(var(--discreet-white)) !important;
    padding: 10px;
  }

  #contact_tooltip{
    background-color: transparent;
    border: 1.5px solid var(--dark-blue) !important;
    margin-left: 6px !important;
    padding: 10px;
  }

  div.bs-tooltip-auto[x-placement^=right]{
    z-index: 0 !important;
  }

  div.bs-tooltip-auto[x-placement^=right]>span.arrow{
    margin-left: 3px !important;
  }

  div.bs-tooltip-auto[x-placement^=right]>span.arrow:before{
    border-right-color: var(--dark-blue) !important;
  }

  li.badge_li>span{
    background-color: var(--light-purple);
    border: solid 1px var(--wine);
    color: rgba(var(--discreet-white));
  }
  
  .list-none{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  #header_contact_progress_layout{
    position: absolute;
    pointer-events: none;
    height: 100%;
    width: 100%;
    
    display: grid;
    display: -ms-grid;
    grid-row-gap: 0px;
    grid-column-gap: 10px;
    grid-template-rows: 6% auto 6%;
    -ms-grid-rows: 6% 0px auto;
    grid-template-columns: 7% 1fr 7%;
    ms-grid-columns:7% 1fr 7%;
        grid-template-areas:
    "header header header"
    "contact_sidebar content_grid indicator"
    "contact_sidebar down_arrow_indicator indicator";
    z-index: 1;
  }

  #header{
    grid-area: header;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
  }

  .contact_sidebar {
    grid-area: contact_sidebar;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    padding: 63.4vh 10% 0px 10%;
  }

  #content{
    grid-area: content_grid;
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    position: relative;
    z-index: -1000;
  }

  #header{
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
  }

  .contact_sidebar{
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }

  #content{
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }

  #indicator{
    -ms-grid-row: 3;
    -ms-grid-column: 5;
  }

  #down_arrow_indicator{
    grid-area: down_arrow_indicator;
  }

  #indicator_container{
    grid-area: indicator;
  }

  .contact_sidebar{
    position: relative;
  }

  .contact_sidebar_list{
    position: absolute;
    pointer-events: all !important;
    width: 100%;
  }

  .contact_sidebar_list>li{
    padding-top: 18px;
    padding-bottom: 18px;
  }

  figure.contact_sidebar_icons{
    margin: 0;
    margin-left: 24%;
    width: 40%;
    cursor: pointer;
  }

  figure>svg{
    fill: #8C0034;
    fill: var(--wine) !important;
  }

  .contact_sidebar_list>li>a{
    cursor: auto !important;
  }

  #indicator{
    background-color: gray;
    width: 2%;
    margin: 0px 114%;
  }

  #indicator::before{
    content: "";
    background: #8C0034;
    background: var(--wine);
    margin-top: 0;
    position: absolute;
    /*height: 9.5%;*/
    width: 0.15%;
  }

  #indicator_container>span#span1{
    position: absolute;
    margin-top: 9.8%;
    margin-left: 2.9%;
    height: 27px;
    color: #8C0034;
    color: var(--wine);
  }

  #indicator_container>span#span2{
    position: absolute;
    margin-top: 1.3%;
    margin-left: 2.9%;
    height: 27px;
    color: #8C0034;
    color: var(--wine);
  }

  #drag_drop_span{
    position: absolute;
  }

  #drag_drop_span>p{
    position: relative;
  }

  #drag_drop_span>p{
    text-align: center !important;
  }

  figure#down_arrow_indicator_figure{
    position: relative;
    text-align: center;
  }

  figure#down_arrow_indicator_figure>svg{
    pointer-events: all;
  }

  .scroll-container{
    background-color: rgba(255,245,247, 0.2);
    height: 100vh;
    width: 100%;
  }

  .scroll-container.grain::after {
    -webkit-animation:grain 0.5s steps(6) infinite;
            animation:grain 0.5s steps(6) infinite;
            -ms-animation:grain 0.5s steps(6) infinite;
  }

  .scroll-container::after {
    background-image: url(https://i1.wp.com/machinesdontcare.files.wordpress.com/2009/06/permtexture.png);
    content: "";
    height: 300%;
    left: -50%;
    opacity: 0.2;
    position: fixed;
    top: -110%;
    width: 300%;
  }

  .test-row{
    height: 100%;
  }

  .project_content{
    display: none;
    pointer-events: none;
    position: absolute;
    top: 0%;
    height: 100%;
    width: 100%;
    opacity: 0;
    padding-top: 11%;
    padding-left: 5%;
    padding-right: 4%;
    padding-bottom: 5%;
    z-index: 10;
  }

  div.project_overview>ul{
    color: rgba(var(--discreet-white)) !important;
    font-size: 1.1em;
    font-weight: 200 !important;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: justify;
    text-justify: inter-word;
  }

  li.project_description{
    margin-right: 15px;
  }

  li.project_description>p{
    white-space: pre-line;
  }

  div.project_overview>ul::-webkit-scrollbar{
    width: 6px;
  }

  div.project_overview>ul::-webkit-scrollbar-thumb {
    background: rgba(var(--indigo-blue));
    border-radius: 10px;
  }

  div.project_overview>ul::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--indigo-blue),0.6);
  }

  div#modal_overflow::-webkit-scrollbar{
    width: 6px;
  }

  div#modal_overflow::-webkit-scrollbar-thumb {
    background: rgba(var(--indigo-blue));
    border-radius: 10px;
  }

  div#modal_overflow::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--indigo-blue),0.6);
  }

  .project-div{
    position: relative;
    height: 70%;
    /*width: 28%;*/
    width: 87%;
    margin-left: 9.6%;
    background-color: var(--dark-blue);
    margin-top: 14vh;
    color: rgba(var(--discreet-white)) !important;
    text-align: center; 
    -webkit-box-shadow: 4px 5px 10px 3px rgba(0,0,0,0.55); 
    box-shadow: 4px 5px 10px 3px rgba(0,0,0,0.55);
    z-index: 1;
    -webkit-transform-origin: 10% 50% !important;
            transform-origin: 10% 50% !important;
    display: inline-block !important;
    vertical-align: middle !important;
  }

  .helper{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .helper_div{
    font-size: 0.0001px;
    text-align: center;
    height: 100%;
  }

  .helper_div:before {
    content: '';
    display: inline-block;
    vertical-align: middle;       
    height: 100%; 
  }

  .helper_div:after {
    content: '';
    position: absolute;
    left: 10%;
    top: 32.5%;
    vertical-align: middle;   
    filter: blur(6px);
  }

  .helper_div.project_1:after {
    background: url(../img/dielectric_sensor_panel_img.png);
    background-repeat:no-repeat;
    background-size:100% 100%;
    height: 37%;
    min-width: 80%; 
  }

  .helper_div.project_2:after {
    background: url(../img/cst_panel_img.png);
    background-repeat:no-repeat;
    background-size:100% 100%;
    height: 37%;
    min-width: 80%; 
  }

  .helper_div.project_3:after {
    background: url(../img/neural_network_panel_img.png);
    background-repeat:no-repeat;
    background-size:100% 100%;
    height: 37%;
    min-width: 80%; 
  }

  .helper_div.project_4:after {
    background: url(../img/electric_vehicle_img.png);
    background-repeat:no-repeat;
    background-size:100% 100%;
    height: 37%;
    min-width: 80%; 
  }

  .helper_div>img {
    /*position: relative;*/
    position: absolute;
    left: 10%;
    top: 32%;
    /*background: url(../img/andreakushh_panel.png);*/
    background-repeat:no-repeat;
    background-size:100% 100%;
    vertical-align: middle;
    height: 37%;
    /*min-width: 80%;*/
    width: 80%;
    z-index: 1;
  }

  /*.panel_img{
    background: url(../img/andreakushh_panel.png);
    background-repeat:no-repeat;
    background-size:100% 100%;
    background: #3A6F9A;
    vertical-align: middle;
    min-height: 35%;
    max-height: 38%;
    max-width: 80%;
    box-shadow: -3px 16px 22px 0px rgba(0,0,0,0.67);
      -webkit-box-shadow: -3px 16px 22px 0px rgba(0,0,0,0.67);
      -moz-box-shadow: -3px 16px 22px 0px rgba(0,0,0,0.67);
  }*/

  /*.panel_img:hover{
    filter: blur(0rem);
    transition: 0.4s ease-in-out;
  }*/

  /*.helper::before{
    content: "";
    position: absolute;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .helper::after{
    content: "";
    position: absolute;
    background: url(../img/andreakushh_panel.png);
    background-repeat:no-repeat;
    background-size:100% 100%;
    opacity: 1;
    vertical-align: middle;
    min-height: 35%;
    min-width: 80%;
    max-height: 38%;
    max-width: 80%;
    filter: blur(0rem);
    z-index: 1;
  }*/

  .project-div:hover,.projectTitle:hover,.projectHeader:hover,ul.project_list:hover{
    cursor: default;
  }

  .project-div.transition{

  }

  div#modal_overflow{
    height: 42vh;
    overflow-x: hidden !important;
    overflow-y: auto;
    padding-right: 10px;
  }

  /*START Coming Soon Component*/
  /*=============================*/
  div.row.coming_soon_text{
    color: #2F4666 !important;
    margin-top: 15%;
    height: 100%;
    font-size: 4em;
    font-weight: bold;
    font-family: sans-serif !important;
    text-transform: uppercase;
    text-align: center;
  }

  div.row.coming_soon_text>div{
    color: #2F4666 !important;
  }

  div>div#center_text{
    box-shadow:12px 12px 16px 12px rgba(0, 0, 0, 0.25);
    height: calc(30%);
  }

  .clocks_row{
  }

  .clock_col{
    margin-top: -44% !important;
  }

  .timer-container {
    margin-top: 15%;
    align-items: center;
    justify-content: center;
  }

  .timer-container>div>div.clock_panel {
    background: transparent;
    border: solid 2px;
    padding: 2rem 0.5rem;
    color: var(--wine);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  .timer-container>div>div>span {
    font-size: 2rem;
  }
  /*=============================*/
  /*END Coming Soon Component*/

  .cursor{
    cursor: move !important;
    /*width: 50px;
    height: 50px;*/
    display: inline-block;
    background-color: transparent;
    border: 1.5px solid #8C0034;
    border: 1.5px solid var(--wine);
    border-radius: 50%;
    color: #8C0034;
    color: var(--wine) !important;
    font-size: 12px;
    padding-top: 14px;
    padding-left: 8px;
    pointer-events: none;
    position: fixed;
    z-index: 999;
    top: -26px;
    left: -26px;
    -webkit-transform: translate(50vw, 50vh);
            transform: translate(50vw, 50vh);
  }

  #noiseLayer:hover{
    cursor: -webkit-grab !important;
    cursor: -moz-grab !important;
    cursor: grab !important;
  }

  .scroll-container:active{
    cursor: -webkit-grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: grabbing !important;
  }

  .cursorHover{
    width: 20px;
    height: 20px;
    /*display: inline-block;*/
    display: none;
    border: 1px solid #ffffff;
    border-radius: 50%;
    pointer-events: none;
    position: fixed;
    z-index: 999;
    top: -8px;
    left: -8px;
    -webkit-transform: translate(-8px, -8px);
            transform: translate(-8px, -8px);
  }

  #modalLayer{
    position: absolute;
    background-color: black;
    height: 100%;
    width: 0%;
    opacity: 0.8;
    z-index: 2;
  }

  ul.project_list{
    position: relative;
    
    display: grid;
    display: -ms-grid;
    grid-row-gap: 21%;
    
    grid-template-rows: 1fr 30vh auto;
    -ms-grid-rows: 1fr 21% 30vh 21% auto;
    
    grid-template-columns: 100%;
    -ms-grid-columns: 100%;
    font-size: 9px;
    text-align: left;
    padding: 15vh 0 0px 0;
    z-index: 1;
  }

  ul.project_list > *:nth-child(1){
		-ms-grid-row: 1;
		-ms-grid-column: 1;
  }

  ul.project_list > *:nth-child(2){
		-ms-grid-row: 3;
		-ms-grid-column: 1;
  }

  ul.project_list > *:nth-child(3){
		-ms-grid-row: 5;
		-ms-grid-column: 1;
  }

  li.projectHeader{
    position: relative !important;
    text-align: right;
  }

  li.projectHeader>h4{
    position: relative;
    font-weight: 400 !important;
    letter-spacing: 4px !important;
  }

  li.projectTitle{

  }

  li.projectTitle>h1{
    font-size: 10em;
  font-weight: bold;
  font-family: sans-serif;
  line-height: 1.2; /*MISSING in prod*/
  text-transform: uppercase;
  opacity: 1;
  text-shadow: -6px 6px 15px rgba(0,0,0,0.5), 
               6px -6px 15px rgba(255,255,255,0.8);
  position: relative;
    -webkit-box-align: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    left: -25%;
    width: 130%;
    padding-left: 10px;
    -webkit-box-shadow:12px 12px 16px 0 rgba(0, 0, 0, 0.25);
            box-shadow:12px 12px 16px 0 rgba(0, 0, 0, 0.25);
    /*text-shadow:
    10px 10px 20px rgba(105, 255, 255, 1);
    border-radius: 50px;
    display: flex;
    height: 200px;
    justify-content: center;
    margin-right: 4rem;
    width: 200px;
    position: relative;
    left: -500px;*/
  }

  #close_project_btn_li{
    position: absolute;
    text-align: right;
    /* right: 10px; */
  }

  #close_project_btn{
    color: rgba(var(--indigo-blue)) !important;
  }

  li.projectButtonLi>button,#close_project_btn{
    border: none;
    background-color: transparent !important;
    /*font-size: 1.3rem !important;*/
    color: #8C0034;
    color: var(--wine);
  }

  .demo_btn{
    color: rgba(var(--discreet-white));
    pointer-events: all !important;
    text-decoration: none;
  }

  .demo_btn:hover{
    color: rgba(var(--discreet-white));
    pointer-events: all !important;
    text-decoration: none;
  }

  #close_project_btn,.project_overview>ul{
    pointer-events: all !important;
  }

  .pr_primary_btn{
    background-color: transparent !important;
    border: solid 1px;
    color: #8C0034;
    color: var(--wine);
    /*font-size: 1.3rem !important;*/
    padding: 5px 0px;
    width: 100%;
  }

  .pr_primary_btn:hover{
    border: solid 1px rgba(var(--discreet-white));
  }

  #modal{
    position: relative;
    -webkit-box-flex: 0;
       -moz-box-flex: 0;
        -ms-flex: 0 0 0%;
            flex: 0 0 0%;
    padding: 2% !important;
    font-size: 1.1rem;
    background-color: transparent;
    border: solid 1px rgba(var(--discreet-white));
  }

  .row.modal_row{
    display: none;
    opacity: 0;
  }

  .modal_row>div>div>label{
    font-size: 1rem;
  }

  .action_button_row{
    position: absolute;
    bottom: 8%;
    width: 96%;
  }

  input.primary_input.is-untouched,textarea.primary_input.is-untouched{
    border-color: var(--light-purple) !important;
  }

  input.primary_input,textarea.primary_input{
    background-color: transparent !important;
    border-radius: 0px;
    border-style: none none solid none !important;
    border-width: 2px;
    color: rgba(var(--discreet-white));
  }

  input.primary_input:focus,textarea.primary_input:focus{
    color: rgba(var(--discreet-white));
  }

  input.primary_input::placeholder,textarea.primary_input::placeholder{
    opacity: 0.8;
  }

  input.primary_input.av-invalid.is-invalid,textarea.primary_input.av-invalid.is-invalid{
    border-color: #dc3545 !important;
  }

  input.primary_input.av-valid,textarea.primary_input.av-valid{
    border-color: #4caf50;
  }
}
/*PSEUDO ELEM are declared outside of MEDIA QUERIES in order to be accessible for CSSRule*/
  #indicator::before{
    content: "";
    background: #8C0034;
    background: var(--wine);
    margin-top: 0;
    position: absolute;
    height: 9.75%;/*Set initial indicator height for ANIMATION*/
    width: 0.15%;
  }

  .projectButtonLi::before{
    content: "";
    background-color: var(--wine);
    position: absolute;
    height: 1.5px;
    /*width: 25%;*/
    bottom: -27%;
    left: -0%;
  }

  .cursor::before {
    content: "";
    position: absolute;
    /*bottom: 53px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;*/
    background-image: url(../svg/simple_arrow.svg);
    background-position: center;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    -webkit-transform: var(--cursorUpArrowTransform);
            transform: var(--cursorUpArrowTransform);
    transition-duration: 0.2s;
  }

  .cursor::after {
    content: "";
    position: absolute;
    /*top: 49px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;*/
    background-image: url(../svg/simple_arrow.svg);
    background-position: center;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    -webkit-transform: var(--cursorDownArrowTransform);
            transform: var(--cursorDownArrowTransform);
    transition-duration: 0.2s;
  }
  /*PSEUDO ELEM are declared outside of MEDIA QUERIES in order to be accessible for CSSRule*/
/* END GENERAL PC STYLES */
/* ============================= */

/* ============================= */
/* START HD 1280x720 size */
@media screen and (min-width: 1280px) and (max-width: 1365px){
  html{/*VARIABLES for this media query*/
    --lineIndicatorWidth: 36%;
  }

  .cursor{
    width: 50px;
    height: 50px;
    top: -26px;
    left: -24px;
  }

  .cursor::before {
    bottom: 53px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;
  }

  .cursor::after {
    top: 49px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;
  }

  #span1{
    margin-top: 8.7% !important;
  }

  #indicator{
    height: 41.5%;
    /*margin-top: calc(34vh - 68px) !important;*/
    margin-top: calc(187%);
    margin-left: 55% !important;
  }

  #span1,#span2{
    font-size: 1.3em;
  }

  #drag_drop_span{
    bottom: 1%;
    right: 1.5%;
  }

  #drag_drop_span>p{
    font-size: 0.8em;
  }

  #down_arrow_indicator_figure>svg{
    height: 5vh;
  }

  .pr_primary_btn,li.projectButtonLi>button,#close_project_btn{
    font-size: 1.2rem;
  }

  li.projectHeader>h4{
    font-size: 1.1rem;
    right: -7.7%;
  }

  li.projectTitle>h1{
    font-size: 7.8em !important;
    left: -25%;
    width: 130%;
    padding-left: 10px;
  }

  li.projectButtonLi::before{
    width: var(--lineIndicatorWidth);
  }

  li.projectButtonLi{
    padding-left: 48%;
  }

  .contact_sidebar {
    padding: 60.8vh 10% 0px 10%;
  }

  figure.contact_sidebar_icons{
    width: 45%;
  }

  div.project_overview>ul.overflow_list {
    height: 55vh;
  }

  .demo_btn_li{
    padding: 2% 0 2% 0;
  }

  #close_project_btn_li{
    bottom: 4vh;
    right: 7.5%;
  }

  #modal{
    top: 16vh;
    height: 68vh;
  }

  #modal>div>div.ldio-lbln0584m6g>div{
    width: 300px;
    height: 300px;
    top: calc(50% - 150px);
    left: calc(50% - 160px);
  }
}
/* END HD 1280x720 size */
/* ============================= */

/* ============================= */
/* START 1366x768 (MOST COMMON) size */
@media screen and (min-width: 1366px) and (max-width: 1919px){
  html{/*VARIABLES for this media query*/
    --lineIndicatorWidth: 40%;
  }

  .cursor{
    width: 50px;
    height: 50px;
    top: -26px;
    left: -24px;
  }

  .cursor::before {
    bottom: 53px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;
  }

  .cursor::after {
    top: 49px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;
  }

  #span1{
    margin-top: 8.5% !important;
  }

  #indicator{
    height: 41.5%;
    /*margin-top: calc(24.45vh) !important;*/
    margin-top: calc(175%);
    margin-left: 55% !important;
  }

  #span1,#span2{
    font-size: 1.2em;
  }

  #drag_drop_span{
    bottom: 1%;
    right: 1.5%;
  }

  #drag_drop_span>p{
    font-size: 0.8em;
  }

  #down_arrow_indicator_figure>svg{
    height: 4vh;
    width: 4vh;
  }

  .pr_primary_btn,li.projectButtonLi>button,#close_project_btn{
    font-size: 1.2rem;
  }

  li.projectHeader>h4{
    font-size: 1.2rem;
    right: -7.7%;
  }

  li.projectTitle>h1{
    font-size: 8.3em !important;
    left: -25%;
    width: 130%;
    padding-left: 10px;
  }

  li.projectButtonLi::before{
    width: var(--lineIndicatorWidth);
  }

  li.projectButtonLi{
    padding-left: 50%;
  }

  div.project_overview>ul.overflow_list{
    height: 53vh;
  }

  .demo_btn_li{
    padding: 2% 0 2% 0;
  }

  #close_project_btn_li{
    bottom: 2vh;
    right: 7.2%;
  }

  #modal{
    top: 16vh;
    height: 68vh;
  }

  #modal>div>div.ldio-lbln0584m6g>div{
    width: 300px;
    height: 300px;
    top: calc(50% - 150px);
    left: calc(50% - 160px);
  }

  .contact_sidebar {
    padding: 0px 10% 0px 10% !important;
  }

  .contact_sidebar_list{
    bottom: 2vh !important;
  }

  .contact_sidebar_list>li{
    padding-top: 18px;
    padding-bottom: 18px;
  }

  figure.contact_sidebar_icons{
    width: 40%;
  }
}
/* END 1366x768 (MOST COMMON) size */
/* ============================= */

/* ============================= */
/* START FULL HD 1920x1080 size */
@media screen and (min-width: 1920px) {
  html{/*VARIABLES for this media query*/
    --lineIndicatorWidth: 40%;
  }

  body{
    line-height: 2em !important;
  }

  .cursor{
    width: 80px;
    height: 80px;
    top: -40px;
    left: -40px;
  }

  .cursor::before {
    bottom: 84px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;
  }

  .cursor::after {
    top: 77px;
    left: 0;
    height: 20%;
    width: 38%;
    margin-top: 8%;
    margin-left: 32%;
  }

  #span1{
    margin-top: 8.5% !important;
  }

  #indicator{
    height: 41.5%;
    /*margin-top: calc(24.1vh) !important;*/
    margin-top: calc(175%);
    margin-left: 55% !important;
  }

  #span1,#span2{
    font-size: 1.8em;
  }

  #drag_drop_span{
    bottom: 1%;
    right: 1.5%;
  }

  #drag_drop_span>p{
    font-size: 1.2em;
  }

  #down_arrow_indicator_figure>svg{
    height: 4vh;
  }

  .pr_primary_btn {
    padding: 15px 0px;
  }

  .pr_primary_btn,li.projectButtonLi>button,#close_project_btn{
    font-size: 2rem;
  }

  li.projectHeader>h4{
    font-size: 1.9rem;
    right: -7.7%;
  }

  li.projectTitle>h1{
    font-size: 12em !important;
    left: -25%;
    width: 130%;
    padding-left: 10px;
  }

  li.projectButtonLi::before{
    width: var(--lineIndicatorWidth);
  }

  li.projectButtonLi{
    padding-left: 50%;
  }

  div.project_overview>ul.overflow_list {
    height: 57vh;
    font-size: 1.5em;
  }

  .demo_btn_li{
    padding: 2% 0 2% 0;
  }

  #close_project_btn_li{
    bottom: 3vh;
    right: 7.2%;
  }

  #modal{
    top: 16vh;
    height: 68vh;
  }

  .modal_row>div>div>label {
    font-size: 1.5rem;
  }

  .form-control{
    font-size: 1.5rem !important;
  }

  #modal>div>div.ldio-lbln0584m6g>div{
    width: 300px;
    height: 300px;
    top: calc(50% - 150px);
    left: calc(50% - 160px);
  }

  .contact_sidebar {
    padding: 60vh 10% 0px 10%;
  }

  .contact_sidebar_list>li{
    padding-top: 25px;
    padding-bottom: 25px;
  }

  figure.contact_sidebar_icons{
    width: 48%;
  }

  #modal {
    font-size: 1.5rem;
  }
}
/* END FULL HD 1920x1080 size */
/* ============================= */

/* ============================= */
/* START Anim */
.loadingio-spinner-dual-ring-n1trspcz7t {
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-lbln0584m6g div { -webkit-box-sizing: border-box!important; -moz-box-sizing: border-box!important; box-sizing: border-box!important }
.ldio-lbln0584m6g > div {
  position: absolute;
  /*width: 80%;
  height: 100%;
  top: 21px;
  left: 21px;*/
  border-radius: 50%;
  border: 10px solid #000;
  border-color: #8c0034 transparent #8c0034 transparent;
  -webkit-animation: ldio-lbln0584m6g 1.5s linear infinite;
          animation: ldio-lbln0584m6g 1.5s linear infinite;
}
.ldio-lbln0584m6g > div:nth-child(2) { border-color: transparent }
.ldio-lbln0584m6g > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ldio-lbln0584m6g > div:nth-child(2) div:before, .ldio-lbln0584m6g > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  top: -10px;
  left: 64px;
  background: #8c0034;
  border-radius: 50%;
  -webkit-box-shadow: 0 148px 0 0 #8c0034;
          box-shadow: 0 148px 0 0 #8c0034;
}
.ldio-lbln0584m6g > div:nth-child(2) div:after { 
  left: -10px;
  top: 64px;
  -webkit-box-shadow: 148px 0 0 0 #8c0034;
          box-shadow: 148px 0 0 0 #8c0034;
}
.ldio-lbln0584m6g {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio-lbln0584m6g div { -webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box; }
/* generated by https://loading.io/ */
@-webkit-keyframes ldio-lbln0584m6g {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}
@keyframes ldio-lbln0584m6g {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

@-webkit-keyframes grain {
    0%, 100% {-webkit-transform: translate(0,0);transform: translate(0,0);-ms-transform: translate(0,0);}
    10% {-webkit-transform: translate(-5%,-10%);transform: translate(-5%,-10%);-ms-transform: translate(-5%,-10%);}
    30% {-webkit-transform: translate(3%,-15%);transform: translate(3%,-15%);-ms-transform: translate(3%,-15%);}
    50% {-webkit-transform: translate(12%,9%);transform: translate(12%,9%);-ms-transform: translate(12%,9%);}
    70% {-webkit-transform: translate(9%,4%);transform: translate(9%,4%);-ms-transform: translate(9%,4%);}
    90% {-webkit-transform: translate(-1%,7%);transform: translate(-1%,7%);-ms-transform: translate(-1%,7%);}
  }

  @keyframes grain {
    0%, 100% {-webkit-transform: translate(0,0);transform: translate(0,0);-ms-transform: translate(0,0);}
    10% {-webkit-transform: translate(-5%,-10%);transform: translate(-5%,-10%);-ms-transform: translate(-5%,-10%);}
    30% {-webkit-transform: translate(3%,-15%);transform: translate(3%,-15%);-ms-transform: translate(3%,-15%);}
    50% {-webkit-transform: translate(12%,9%);transform: translate(12%,9%);-ms-transform: translate(12%,9%);}
    70% {-webkit-transform: translate(9%,4%);transform: translate(9%,4%);-ms-transform: translate(9%,4%);}
    90% {-webkit-transform: translate(-1%,7%);transform: translate(-1%,7%);-ms-transform: translate(-1%,7%);}
  }
/* END Anim */
/* ============================= */

/* ============================= */
/* TEMP */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.containers {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.left-container {
  background-color: black;
  height: 100%;
  width: 30%;
  color: white;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
     -moz-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.right-container {
  position: relative;
  background-color: white;
  height: 100%;
  width: 70vw;
}

.center-container {
  position: absolute;
  top: 25vh;
  left: 15vw;
  height: 50vh;
  width: 40vw;
  background-image: url("https://images.unsplash.com/photo-1551522355-dbf80597eba8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
  background-size: cover;
  -webkit-box-shadow: 7px 29px 49px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 7px 29px 49px 0px rgba(0, 0, 0, 0.5);
}

.logo {
  padding: 3rem;
  font-size: 2rem;
  font-family: "helvetica";
}

.info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3rem;
  font-size: 1rem;
}

.info a {
  text-decoration: none;
  margin-left: 1rem;
  color: white;
}

.story {
  position: absolute;
  height: 40%;
  left: 30vw;
  top: 30vh;
}

.story h1 {
  font-size: 9rem;
  margin-bottom: 2rem;
  font-family: "Garamond";
}

.story p {
  margin-left: 0.5rem;
  font-size: 1.2rem;
  padding-right: 20rem;
  font-family: "Garamond";
}

.story p:nth-child(3) {
  margin-top: 5rem;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "helvetica";
}

.menu-container {
  position: absolute;
  top: 3rem;
  right: 4rem;
  width: 50px;
  height: 45px;
}
.menu,
.menu:after,
.menu:before {
  width: 50px;
  height: 5px;
}

.menu {
  position: relative;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  background: black;
}

.menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 15px;
  background: black;
}
.menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  background: black;
}

.social-container {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.social {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.social ul {
  margin-right: 1rem;
  font-weight: 700;
  font-family: "helvetica";
}

.first-block {
  position: absolute;
  width: 0%;
  height: 100vh;
  background: black;
  -webkit-animation: go-left 4s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
          animation: go-left 4s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  left: 0;
}
/* ============================= */

